import "./ToolTip.css";

export default function Tooltip({ children, text, position }) {
	let tooltipPostion;

	switch (position) {
		case "top":
			tooltipPostion = { bottom: "115%", left: "50%", transform: "translateY(-50%)" };
			break;
		case "bottom":
			tooltipPostion = { top: "115%", left: "50%", transform: "translateY(-50%)" };
			break;
		case "left":
			tooltipPostion = { top: "50%", right: "115%", transform: "translateY(-50%)" };
			break;
		case "right":
			tooltipPostion = { top: "50%", left: "115%", transform: "translateY(-50%)" };
			break;
		default:
			tooltipPostion = { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
	}

	return (
		<div className="tooltip">
			<span className="tooltiptext" style={tooltipPostion}>
				{text}
			</span>
			{children}
		</div>
	);
}

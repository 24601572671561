import AlphaModal from "../AlphaModal/AlphaModal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Cart from "./Cart";
import "./Market.css";

export default function Market() {
	const navigate = useNavigate();
	const [showAlphaModal, setShowAlphaModal] = useState(false);
	return (
		<div className="market-container">
			<div className="market-wrapper">
				<div className="market-header">
					<h2>welcome to the market!</h2>
					<p>
						the market is a place where you can see your cart, buy unowned squares, place offers on owned squares, and put any
						squares that you own up for sale.
					</p>
					<div className="half-btns">
						<button className="profile-btn" onClick={() => setShowAlphaModal(true)}>
							offers you recieved <span className="alpha-btn-message">- beta feature</span>
						</button>
						<div className="half-btn-spacer"></div>
						<button className="profile-btn" onClick={() => setShowAlphaModal(true)}>
							offers you made <span className="alpha-btn-message">- beta feature</span>
						</button>
					</div>
					<div className="half-btns">
						<button className="profile-btn" style={{ border: "1px solid var(--Green)", pointerEvents: "none" }}>
							cart
						</button>
					</div>
					<p>
						check out the{" "}
						<span className="statistics-link" onClick={() => navigate("/statistics")}>
							statistics page
						</span>{" "}
						for market trends, and more.
					</p>
				</div>
				<Cart />
			</div>
			<AlphaModal showAlphaModal={showAlphaModal} setShowAlphaModal={setShowAlphaModal} />
		</div>
	);
}

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PageWrapper from "./Layouts/PageWrapper/PageWrapper";
import { useAuth } from "./Components/Auth/CustomAuthHook";
import EmailVerification from "./Components/Auth/Verification";
import Dashboard from "./Components/Dashboard/Dashboard";
import Statistics from "./Components/Statistics/Statistics";
import VerifyEmail from "./Components/Auth/VerifyEmail";
import ForgotPwd from "./Components/Auth/ForgotPwd";
import Security from "./Components/Security/Security";
import Privacy from "./Components/Privacy/Privacy";
import Register from "./Components/Auth/Register";
import Market from "./Components/Market/Market";
import Terms from "./Components/Terms/Terms";
import Home from "./Components/Home/Home";
import Policy from "./Components/Policy/Policy";
import Login from "./Components/Auth/Login";
import Test from "./Components/Test/Test";
import { useEffect } from "react";
import "./App.css";

function App() {
	const { loading, isVerified } = useAuth();

	useEffect(() => {
		//fix openseadragon bug
		const removeUnwantedCanvas = () => {
			const canvas = document.querySelector(
				"canvas[style='inset: 0px; pointer-events: none; position: fixed; z-index: 1000000000;']"
			);
			if (canvas) canvas.parentNode.removeChild(canvas);
		};
		const observer = new MutationObserver(removeUnwantedCanvas);
		observer.observe(document, { childList: true, subtree: true });

		//adjust for mobile varibale height
		const setVh = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};
		setVh();
		window.addEventListener("resize", setVh);

		return () => {
			window.removeEventListener("resize", setVh);
			observer.disconnect();
		};
	}, []);

	if (loading) {
		return (
			<div className="App">
				<div className="spinner"></div>
			</div>
		);
	}

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route
						path="/dashboard"
						element={
							isVerified ? (
								<PageWrapper>
									<Dashboard />
								</PageWrapper>
							) : (
								<Navigate to="/verification" />
							)
						}
					/>
					<Route
						path="/statistics"
						element={
							isVerified ? (
								<PageWrapper>
									<Statistics />
								</PageWrapper>
							) : (
								<Navigate to="/verification" />
							)
						}
					/>
					<Route
						path="/verification"
						element={
							<PageWrapper>
								<EmailVerification />
							</PageWrapper>
						}
					/>
					<Route
						path="/verify"
						element={
							<PageWrapper>
								<VerifyEmail />
							</PageWrapper>
						}
					/>
					<Route
						path="/security"
						element={
							<PageWrapper>
								<Security />
							</PageWrapper>
						}
					/>
					<Route
						path="/register"
						element={
							<PageWrapper>
								<Register />
							</PageWrapper>
						}
					/>
					<Route
						path="/forgot"
						element={
							<PageWrapper>
								<ForgotPwd />
							</PageWrapper>
						}
					/>
					<Route
						path="/privacy"
						element={
							<PageWrapper>
								<Privacy />
							</PageWrapper>
						}
					/>
					<Route
						path="/policy"
						element={
							<PageWrapper>
								<Policy />
							</PageWrapper>
						}
					/>
					<Route
						path="/market"
						element={
							isVerified ? (
								<PageWrapper>
									<Market />
								</PageWrapper>
							) : (
								<Navigate to="/verification" />
							)
						}
					/>
					<Route
						path="/login"
						element={
							<PageWrapper>
								<Login />
							</PageWrapper>
						}
					/>
					<Route
						path="/terms"
						element={
							<PageWrapper>
								<Terms />
							</PageWrapper>
						}
					/>
					<Route
						path="/"
						element={
							<PageWrapper>
								<Home />
							</PageWrapper>
						}
					/>
					<Route
						path="/test"
						element={
							<PageWrapper>
								<Test />
							</PageWrapper>
						}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;

import UpdateUsername from "./UpdateUsername/UpdateUsername";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useDashboardModals } from "./CustomDashboardHook";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import UpdateEmail from "./UpdateEmail/UpdateEmail";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "../../Api/user";
import { auth } from "../Auth/Firebase";
import "./Dashboard.css";
import AlphaModal from "../AlphaModal/AlphaModal";
import Modal from "../../Layouts/Modal/Modal";
import GiveFeedback from "./GiveFeedback/GiveFeedback";

export default function Dashboard() {
	const [showAlphaModal, setShowAlphaModal] = useState(false);
	const navigate = useNavigate();
	const goMarket = () => {
		navigate("/market");
	};
	const [user, setUser] = useState({});
	const dashboardModals = useDashboardModals();

	async function logout() {
		try {
			await signOut(auth).then(() => {
				navigate("/login");
			});
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	}

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				getUser(user.email).then((user) => {
					setUser(user);
				});
			} else {
				navigate("/verification");
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="profile-wrapper">
			<div className="profile-container">
				<div className="profile-block  mobile-first-block">
					<h1>your dashboard</h1>
					<h5>from this page you can access the market, manage your squares, and view your profile information.</h5>
					<h5>
						this website is currently in 'alpha', and as such you will notice some features that are incomplete or
						unavaliable. if you have any feedback for the features currently in place please take the time to fill out the
						feedback form.
					</h5>
					<button className="profile-btn" onClick={() => dashboardModals.setShowFeedback(true)}>
						leave feedback
					</button>
				</div>
				<div className="profile-block">
					<h2>market</h2>
					<p>you've given 0 offers</p>
					<p>you've got 0 active offers on your squares</p>
					<button onClick={goMarket} className="profile-btn">
						enter the market
					</button>
				</div>

				<div className="profile-block">
					<h2>squares</h2>
					<p>you currently own 0 squares</p>
					<p>you rank #1</p>
					<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
						currently owned squares <span className="alpha-btn-message">- beta feature</span>
					</button>
					<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
						your square history <span className="alpha-btn-message">- beta feature</span>
					</button>
				</div>

				<div className="profile-block">
					<h2>notifications</h2>
					<p>unread: 0</p>
					<p>recent: none.</p>
					<div className="half-btns">
						<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
							see unread <span className="alpha-btn-message">- beta feature</span>
						</button>
						<div className="half-btn-spacer"></div>
						<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
							see most recent <span className="alpha-btn-message">- beta feature</span>
						</button>
					</div>
					<div className="half-btns">
						<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
							see history <span className="alpha-btn-message">- beta feature</span>
						</button>
						<div className="half-btn-spacer"></div>
						<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
							delete all <span className="alpha-btn-message">- beta feature</span>
						</button>
					</div>
				</div>

				<div className="profile-block">
					<h2>profile</h2>
					<p>email: {user.email}</p>
					<p>username: {user.username}</p>
					<div className="half-btns">
						<button onClick={logout} className="profile-btn">
							logout
						</button>
						<div className="half-btn-spacer"></div>
						<button onClick={dashboardModals.openUpdateUsername} className="profile-btn">
							update username
						</button>
					</div>
					<div className="half-btns">
						<button onClick={dashboardModals.openUpdateEmail} className="profile-btn">
							update email
						</button>
						<div className="half-btn-spacer"></div>
						<button onClick={dashboardModals.openDelectAccount} className="profile-btn">
							delecte account
						</button>
					</div>
				</div>

				<div className="profile-block">
					<h2>finances</h2>
					<p>pay pal: we don't have your payment information yet.</p>
					<p>payment verified? false</p>
					<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
						update payment method <span className="alpha-btn-message">- beta feature</span>
					</button>
					<button onClick={() => setShowAlphaModal(true)} className="profile-btn">
						verify payment method <span className="alpha-btn-message">- beta feature</span>
					</button>
				</div>
			</div>
			<Modal showModal={dashboardModals.showFeedback} setShowModal={dashboardModals.setShowFeedback}>
				<GiveFeedback />
			</Modal>
			<Modal showModal={dashboardModals.showDeleteAccount} setShowModal={dashboardModals.setShowDeleteAccount}>
				<DeleteAccount />
			</Modal>
			<Modal showModal={dashboardModals.showUpdateEmail} setShowModal={dashboardModals.setShowUpdateEmail}>
				<UpdateEmail />
			</Modal>
			<Modal showModal={dashboardModals.showUpdateUsername} setShowModal={dashboardModals.setShowUpdateUsername}>
				<UpdateUsername />
			</Modal>
			<AlphaModal showAlphaModal={showAlphaModal} setShowAlphaModal={setShowAlphaModal} />
		</div>
	);
}

import {
	Figura,
	FiguraEmail,
	FiguraHidden,
	FiguraLabel,
	FiguraPassword,
	FiguraSubmitBtn,
	FiguraTitle,
} from "react-figura";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";
import "./Auth.css";

export default function Login() {
	const navigate = useNavigate();
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const goRegistration = () => {
		navigate("/register");
	};
	const goForgot = () => {
		navigate("/forgot");
	};
	const goHome = () => {
		navigate("/");
	};

	async function handleLogin({ email, password }) {
		setShowFailure({ bool: false, message: "" });

		try {
			await signInWithEmailAndPassword(auth, email, password).then(() => {
				navigate("/");
			});
		} catch (error) {
			setShowFailure({ bool: true, message: "Invalid login credentials." });
		}
	}

	return (
		<>
			<div className="auth-container">
				<Figura formId="login" formStyle="figura-form" onSubmit={handleLogin}>
					<FiguraTitle titleStyle="figura-title">login</FiguraTitle>

					<FiguraEmail name="email" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">email:</FiguraLabel>
					</FiguraEmail>

					<FiguraPassword name="password" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">password:</FiguraLabel>
					</FiguraPassword>

					<FiguraHidden />

					<FiguraSubmitBtn buttonStyle="figura-button">submit</FiguraSubmitBtn>
				</Figura>

				{showFailure.bool && <p className="not-verified ver-left">{showFailure.message}</p>}

				<p className="lower-message">if you dont have an account please click below to create one.</p>

				<button onClick={goRegistration} className="figura-button">
					create account
				</button>

				<button onClick={goHome} className="figura-button">
					continue as guest
				</button>

				<p className="forgot" onClick={goForgot}>
					forgot password?
				</p>
			</div>
		</>
	);
}

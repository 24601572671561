import { Figura, FiguraEmail, FiguraHidden, FiguraLabel, FiguraSubmitBtn, FiguraTitle } from "react-figura";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";

export default function ForgotPwd() {
	const navigate = useNavigate();
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const goLogin = () => {
		navigate("/login");
	};

	async function handleForgotPassword({ email }) {
		setShowFailure({ bool: false, message: "" });
		setShowSuccess(false);
		try {
			await sendPasswordResetEmail(auth, email).then(() => {
				setShowSuccess(true);
			});
		} catch (error) {
			setShowFailure({ bool: true, message: "Error sending password reset email" });
		}
	}

	return (
		<>
			<div className="auth-container">
				<Figura formId="forgot" formStyle="figura-form" onSubmit={handleForgotPassword}>
					<FiguraTitle titleStyle="figura-title">forgotten password</FiguraTitle>

					<FiguraEmail name="email" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">email:</FiguraLabel>
					</FiguraEmail>

					<FiguraHidden />

					<FiguraSubmitBtn buttonStyle="figura-button">submit</FiguraSubmitBtn>
				</Figura>

				{showFailure.bool && <p className="not-verified ver-left">{showFailure.message}</p>}
				{showSuccess && <p className="verified ver-left">we sent you an email with further instructions.</p>}

				<p className="lower-message">
					to reset your password provide your email above and we will send you an email with reset instructions.
				</p>

				<button onClick={goLogin} className="figura-button">
					back to login
				</button>
			</div>
		</>
	);
}

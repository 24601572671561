import {
	Figura,
	FiguraHidden,
	FiguraLabel,
	FiguraPassword,
	FiguraSubmitBtn,
	FiguraText,
	FiguraTitle,
} from "react-figura";
import { EmailAuthProvider, reauthenticateWithCredential, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../../Api/user";
import { auth } from "../../Auth/Firebase";
import { useState } from "react";
import "./DeleteAccount.css";

export default function DeleteAccount() {
	const navigate = useNavigate();
	const [stepTwo, setStepTwo] = useState(false);
	const [messedUp, setMessedUp] = useState(false);
	const [isReauthenticated, setReauthenticated] = useState(false);
	const [authWarning, setAuthWarning] = useState({ bool: false, attempts: 0 });

	async function proceed({ understandFirst }) {
		if (understandFirst) {
			if (understandFirst === "I Understand") {
				setStepTwo(true);
			} else {
				setMessedUp(true);
			}
		}
	}

	function secondChance({ understandSecond }) {
		if (understandSecond) {
			if (understandSecond === "I Understand") {
				setStepTwo(true);
			} else {
				console.log("lockout account");
				setMessedUp(true);
			}
		}
	}

	async function reauthenticate({ password }) {
		try {
			const user = auth.currentUser;
			const credential = EmailAuthProvider.credential(user.email, password);

			await reauthenticateWithCredential(user, credential);
			setAuthWarning({ bool: false, attempts: authWarning.attempts });
			setReauthenticated(true);
		} catch (error) {
			if (error.code === "auth/missing-password") {
				console.error(error);
			} else {
				setReauthenticated(false);
				setAuthWarning({ bool: true, attempts: authWarning.attempts + 1 });
				console.error("Error during re-authentication:", error);
				if (authWarning.attempts >= 1) {
					logout();
				}
			}
		}
	}

	async function deleteAccount() {
		try {
			const firebaseUser = auth.currentUser;
			await deleteUser(firebaseUser.email);
			await firebaseUser.delete();
			navigate("/");
		} catch (error) {
			console.error("Error deleting account:", error);
		}
	}

	async function logout() {
		try {
			await signOut(auth).then(() => {
				navigate("/login");
			});
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	}

	return (
		<div className="delete-account">
			{!stepTwo ? (
				<>
					{!messedUp ? (
						<>
							<h1>delete account</h1>
							<p className="warning-delete">
								if you choose to perform this action your account and all associated data will be permanently deleted from our
								database and history, to include any currently or previously owned squares.
							</p>
							<br />
							<p className="warning-red"> this action is irreversible!</p>
							<p className="warning-red"> type "I Understand" below to continue.</p>
							<Figura formID="understand" formStyle="figura-form figura-modal" onSubmit={proceed}>
								<FiguraText name="understandFirst" inputStyle="figura-input" errorStyle="figura-error" />
								<FiguraHidden />
								<FiguraSubmitBtn buttonStyle="figura-button figura-modal">continue</FiguraSubmitBtn>
							</Figura>
						</>
					) : (
						<>
							<h1>delete account warning</h1>
							<p className="warning-red">
								you have one more chance to get this correct or your account will be locked out. type "I Understand" below to
								continue
							</p>
							<Figura formID="secondChance" formStyle="figura-form figura-modal" onSubmit={secondChance}>
								<FiguraText name="understandSecond" inputStyle="figura-input" errorStyle="figura-error" />
								<FiguraHidden />
								<FiguraSubmitBtn buttonStyle="figura-button figura-modal">submit</FiguraSubmitBtn>
							</Figura>
						</>
					)}
				</>
			) : (
				<>
					<Figura formID="reauth" formStyle="figura-form figura-modal" onSubmit={reauthenticate}>
						<FiguraTitle titleStyle="figura-title">reauthenticate</FiguraTitle>
						<FiguraPassword name="password" inputStyle="figura-input" errorStyle="figura-error">
							<FiguraLabel labelStyle="figura-label">password:</FiguraLabel>
						</FiguraPassword>
						<FiguraHidden />
						<FiguraSubmitBtn buttonStyle="figura-button figura-modal">submit</FiguraSubmitBtn>
					</Figura>

					{authWarning.bool && <p className="warning-red">that password is incorrect, you have 1 attempt remaining</p>}

					{isReauthenticated && (
						<>
							<p className="warning-red" style={{ marginTop: "20px" }}>
								this is your final chance to back out, if you press the delete button below your account will be permanently
								deleted and any owned squares will be removed from your possession.
							</p>
							<button onClick={deleteAccount} className="profile-btn big-red">
								delete account
							</button>
						</>
					)}
				</>
			)}
		</div>
	);
}

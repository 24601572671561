import { onAuthStateChanged, sendEmailVerification, reload } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";

export default function EmailVerification() {
	const [user, setUser] = useState(null);
	const [emailSent, setEmailSent] = useState(false);
	const navigate = useNavigate();
	const goHome = () => {
		navigate("/");
	};
	const goLogin = () => {
		navigate("/login");
	};
	const goRegistration = () => {
		navigate("/register");
	};
	const goLoginAndReload = () => {
		navigate("/login");
		window.location.reload();
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
			if (currentUser) {
				await reload(currentUser);
				setUser(currentUser);
			} else {
				setUser(null);
			}
		});
		return () => unsubscribe();
	}, []);

	function resendEmail() {
		if (user) {
			sendEmailVerification(user)
				.then(() => {
					setEmailSent(true);
				})
				.catch((error) => {
					console.error("Error sending verification email: ", error);
				});
		}
	}

	if (user && !user.emailVerified) {
		return (
			<>
				<div className="auth-container">
					{emailSent ? (
						<p className="verified">
							we've sent you a new verification email! if you don't see the email in your inbox be sure to check your junk/spam
							box. Look for an email from noreply@pyxis-d1790.firebaseapp.com.
						</p>
					) : (
						<p className="not-verified">
							your email has not been verified. if you need to request another verification email click the button below.
						</p>
					)}
					<button onClick={resendEmail} className="figura-button">
						resend email
					</button>
					<p className="plain-para">or</p>
					<button onClick={goHome} className="figura-button">
						continue as guest
					</button>
				</div>
			</>
		);
	} else if (!user) {
		return (
			<>
				<div className="auth-container">
					<p className="not-verified">to access this page you need to have an account and be logged in.</p>
					<button onClick={goLogin} className="figura-button">
						login
					</button>
					<button onClick={goRegistration} className="figura-button">
						register
					</button>
					<button onClick={goHome} className="figura-button">
						continue as guest
					</button>
				</div>
			</>
		);
	} else if (user.emailVerified) {
		return (
			<>
				<div className="auth-container">
					<p className="verified">your email has been verified!</p>
					<button onClick={goLoginAndReload} className="figura-button">
						click here to login
					</button>
				</div>
			</>
		);
	}
}

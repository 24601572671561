import { cartTotal, getCart, getCartRange } from "../../Api/cart";
import { useRegularOsdCanvas } from "./Hooks/CustomRegularOSDHook";
import BigToggleButton from "./BigToggleButton/BigToggleButton";
import SquareInfo from "./RegularSquareInfo/SquareInfo";
import ToolbarUtil from "./Toolbars/ToolbarUtil";
import ToolbarOsd from "./Toolbars/ToolbarOsd";
import { useEffect, useState } from "react";
import { auth } from "../Auth/Firebase";
import "./Canvas.css";
import { getOwnedSquares } from "../../Api/square";

export default function RegularMode({ setIsMultiSelect }) {
	const [squareData, setSquareData] = useState(null);
	const [squareDataLoading, setSquareDataLoading] = useState(false);
	const [cartCount, setCartCount] = useState(0);
	const osdCanvas = useRegularOsdCanvas({ setSquareData, setSquareDataLoading });

	useEffect(() => {
		if (auth.currentUser) {
			cartTotal(auth.currentUser.email).then((total) => setCartCount(total));
		}
	}, []);

	useEffect(() => {
		if (auth.currentUser) {
			getOwnedSquares(auth.currentUser.email).then((squares) => {
				osdCanvas.applyOwnedOverlays(squares);
			});

			getCart(auth.currentUser.email).then((singles) => {
				getCartRange(auth.currentUser.email).then((range) => {
					osdCanvas.applyOverlays(singles, range);
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osdCanvas.applyOverlays, cartCount]);

	function updateCartCount() {
		if (auth.currentUser) {
			cartTotal(auth.currentUser.email).then((total) => setCartCount(total));
		}
	}

	if (osdCanvas.showError) {
		return (
			<p className="osd-error">
				There was a problem loading the canvas, please refresh the page or try again later if this issue persists. We apologize for the
				inconvenience.
			</p>
		);
	}

	return (
		<>
			<div ref={osdCanvas.viewerContainerRef} className="grid">
				<ToolbarOsd isFullScreen={osdCanvas.isFullScreen} isMultiSelect={false} />

				<ToolbarUtil isFullScreen={osdCanvas.isFullScreen} cartCount={cartCount} zoomToSquare={osdCanvas.zoomToSquare} />

				<BigToggleButton setIsMultiSelect={setIsMultiSelect} isMultiSelect={false} />

				<SquareInfo
					squareDataLoading={squareDataLoading}
					setSquareDataLoading={setSquareDataLoading}
					squareData={squareData}
					setSquareData={setSquareData}
					updateCartCount={updateCartCount}
				/>

				{osdCanvas.isLoading && <div className="spinner"></div>}
			</div>
		</>
	);
}

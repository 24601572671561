import Modal from "../../Layouts/Modal/Modal";
import "./AlphaModal.css";

export default function AlphaModal(props) {
	const { showAlphaModal, setShowAlphaModal, message } = props;

	function determineMessage() {
		if (message) {
			return message;
		} else {
			return `the feature your trying to access is 
            not avaliable in the 'alpha' edition of pyxis. this feature will become avaliable once we move into 'beta'. 
            check our socials, or your email for the 'beta' release date!`;
		}
	}

	return (
		<Modal showModal={showAlphaModal} setShowModal={setShowAlphaModal}>
			<div className="alpha-modal">
				<h2>coming soon!</h2>
				<p>{determineMessage()}</p>
				<button onClick={() => setShowAlphaModal(false)} className="profile-btn">
					got it!
				</button>
			</div>
		</Modal>
	);
}

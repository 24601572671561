const API_URL = "https://api.pyxisgrid.com";

export function postUser(username, email) {
	return fetch(`${API_URL}/user/post_user`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ username, email }),
	}).catch((error) => console.error("Error:", error));
}

export function userExists(username, email) {
	console.log(username);
	return fetch(`${API_URL}/user/user_exists`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ username, email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getUser(email) {
	return fetch(`${API_URL}/user/get_user`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function deleteUser(email) {
	return fetch(`${API_URL}/user/delete_user`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function updateReplaceEmail(oldEmail, newEmail) {
	return fetch(`${API_URL}/user/update_email`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ oldEmail, newEmail }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function updateReplaceUsername(oldUsername, newUsername) {
	return fetch(`${API_URL}/user/update_username`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ oldUsername, newUsername }),
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				throw new Error(`Server response: ${response.status}`);
			}
		})
		.catch((error) => console.error("Error:", error));
}

export function getUserId(email) {
	return fetch(`${API_URL}/user/get_user_id?email=${email}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

import "./Statistics.css";
import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Scrollbar } from "smooth-scrollbar-react";
import AlphaModal from "../AlphaModal/AlphaModal";
import useBreakpoint from "../../Helpers/useBreakpoints";

const data = [
	{
		name: "2024-03-24",
		price: 1.0,
	},
	{
		name: "2024-03-25",
		price: 1.2,
	},
	{
		name: "2024-03-26",
		price: 1.8,
	},
	{
		name: "2024-03-27",
		price: 2.25,
	},
	{
		name: "2024-03-28",
		price: 2.5,
	},
	{
		name: "2024-03-29",
		price: 4.0,
	},
	{
		name: "2024-03-30",
		price: 6.0,
	},
];

export default function Statistics() {
	const [selected, setSelected] = useState("Days");
	const [showAlphaModal, setShowAlphaModal] = useState(true);
	const breakpoint = useBreakpoint(1160);
	const alphaMessage = `the statistics page you are viewing is a demo version for the 'alpha' edition of this application.
            all of the data you see is dummy data and does not reflect the actual statistics of the application. we plan on 
            releasing the 'beta' edition soon, which will have real-time statistics and data.`;

	return (
		<>
			<Scrollbar
				damping={0.05}
				thumbMinSize={20}
				renderByPixels={true}
				alwaysShowTracks={false}
				continuousScrolling={true}
			>
				<div className="statistics-container">
					<h2>
						statistics <span className="alpha-btn-message">- real-time statistics coming in beta</span>
					</h2>
					<div className="statistics">
						<div className="statistics-content">
							<div className="statistics-header">
								<h3>avg price per square</h3>
								<select value={selected} onChange={(e) => setSelected(e.target.value)}>
									<option value="Minutes">Minutes</option>
									<option value="Hours">Hours</option>
									<option value="Days">Days</option>
									<option value="Weeks">Weeks</option>
									<option value="Months">Months</option>
									<option value="Years">Years</option>
								</select>
							</div>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									width={500}
									height={300}
									data={data}
									margin={{
										top: 5,
										right: 30,
										left: breakpoint ? -20 : 20,
										bottom: 20,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip contentStyle={{ backgroundColor: "var(--Black)", color: "var(--White)" }} />
									<Legend />
									<Line type="monotone" dataKey="price" stroke="var(--Green)" activeDot={{ r: 8 }} />
								</LineChart>
							</ResponsiveContainer>
						</div>
						<div className="statistics-content">
							<div className="statistics-header">
								<h3>transactions</h3>
								<select value={selected} onChange={(e) => setSelected(e.target.value)}>
									<option value="Minutes">Minutes</option>
									<option value="Hours">Hours</option>
									<option value="Days">Days</option>
									<option value="Weeks">Weeks</option>
									<option value="Months">Months</option>
									<option value="Years">Years</option>
								</select>
							</div>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									width={500}
									height={300}
									data={data}
									margin={{
										top: 5,
										right: 30,
										left: breakpoint ? -20 : 20,
										bottom: 20,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip contentStyle={{ backgroundColor: "var(--Black)", color: "var(--White)" }} />
									<Legend />
									<Line type="monotone" dataKey="price" stroke="var(--Green)" activeDot={{ r: 8 }} />
								</LineChart>
							</ResponsiveContainer>
						</div>
						<div className="statistics-content">
							<div className="statistics-header">
								<h3>pyxis users</h3>
								<select value={selected} onChange={(e) => setSelected(e.target.value)}>
									<option value="Minutes">Minutes</option>
									<option value="Hours">Hours</option>
									<option value="Days">Days</option>
									<option value="Weeks">Weeks</option>
									<option value="Months">Months</option>
									<option value="Years">Years</option>
								</select>
							</div>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									width={500}
									height={300}
									data={data}
									margin={{
										top: 5,
										right: 30,
										left: breakpoint ? -20 : 20,
										bottom: 20,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip contentStyle={{ backgroundColor: "var(--Black)", color: "var(--White)" }} />
									<Legend />
									<Line type="monotone" dataKey="price" stroke="var(--Green)" activeDot={{ r: 8 }} />
								</LineChart>
							</ResponsiveContainer>
						</div>
						<div className="statistics-content">
							<div className="statistics-header">
								<h3>pyxis visits</h3>
								<select value={selected} onChange={(e) => setSelected(e.target.value)}>
									<option value="Minutes">Minutes</option>
									<option value="Hours">Hours</option>
									<option value="Days">Days</option>
									<option value="Weeks">Weeks</option>
									<option value="Months">Months</option>
									<option value="Years">Years</option>
								</select>
							</div>
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									width={500}
									height={300}
									data={data}
									margin={{
										top: 5,
										right: 30,
										left: breakpoint ? -20 : 20,
										bottom: 20,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip contentStyle={{ backgroundColor: "var(--Black)", color: "var(--White)" }} />
									<Legend />
									<Line type="monotone" dataKey="price" stroke="var(--Green)" activeDot={{ r: 8 }} />
								</LineChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
			</Scrollbar>
			<AlphaModal showAlphaModal={showAlphaModal} setShowAlphaModal={setShowAlphaModal} message={alphaMessage} />
		</>
	);
}

import { getCart, getCartRange, removeRange, removeSquare, reserveSquares } from "../../Api/cart";
import Modal from "../../Layouts/Modal/Modal";
import { useEffect, useState } from "react";
import { auth } from "../Auth/Firebase";

export default function Cart() {
	const [cartSingles, setCartSingles] = useState([]);
	const [cartRanges, setCartRanges] = useState([]);
	const [loading, setLoading] = useState(false);
	const [removing, setRemoving] = useState([]);
	const [showReserveModal, setShowReserveModal] = useState(false);
	const [reserveData, setReserveData] = useState({});
	const [reserveStatus, setReserveStatus] = useState("idle");

	useEffect(() => {
		setLoading(true);
		getCart(auth.currentUser.email)
			.then((data) => {
				setCartSingles(data);
			})
			.then(() => {
				getCartRange(auth.currentUser.email).then((data) => {
					setCartRanges(data);
					setLoading(false);
				});
			});
	}, []);

	function removeFromCartSingle(squareID) {
		setRemoving((prev) => [...prev, squareID]);
		setTimeout(() => {
			removeSquare(squareID, auth.currentUser.email).then(() => {
				getCart(auth.currentUser.email)
					.then((data) => {
						setCartSingles(data);
					})
					.then(() => {
						getCartRange(auth.currentUser.email).then((data) => {
							setCartRanges(data);
							setLoading(false);
						});
					});
			});
		}, 200);
	}

	function removeFromCartRange(rangeID) {
		setRemoving((prev) => [...prev, rangeID]);
		setTimeout(() => {
			removeRange(rangeID, auth.currentUser.email).then(() => {
				getCart(auth.currentUser.email)
					.then((data) => {
						setCartSingles(data);
					})
					.then(() => {
						getCartRange(auth.currentUser.email).then((data) => {
							setCartRanges(data);
							setLoading(false);
						});
					});
			});
		}, 200);
	}

	function determineReservationMessage() {
		if (!reserveData.total) {
			return `You are about to reserve square #${reserveData.id}.`;
		} else {
			return `You are about to reserve ${reserveData.total} squares in a range from row 
            ${reserveData.start_row} to row ${reserveData.end_row} and column ${reserveData.start_column} 
            to column ${reserveData.end_column}.`;
		}
	}

	function getSquareIdsForRange() {
		const squareIds = [];
		for (let i = reserveData.start_row; i <= reserveData.end_row; i++) {
			for (let j = reserveData.start_column; j <= reserveData.end_column; j++) {
				squareIds.push((i - 1) * 1000 + j);
			}
		}
		return squareIds;
	}

	function handleReservingSquares() {
		setReserveStatus("pending");
		if (!reserveData.total) {
			reserveSquares([reserveData.id], auth.currentUser.email).then((res) => {
				if (res.status === 200) {
					setReserveStatus("success");
					removeFromCartSingle(reserveData.id);
				} else if (res.status === 409) {
					setReserveStatus("taken");
				} else if (res.status === 403) {
					setReserveStatus("limit");
				} else {
					setReserveStatus("error");
				}
			});
		} else {
			reserveSquares(getSquareIdsForRange(), auth.currentUser.email).then((res) => {
				if (res.status === 200) {
					setReserveStatus("success");
					removeFromCartRange(reserveData.id);
				} else if (res.status === 409) {
					setReserveStatus("taken");
				} else if (res.status === 403) {
					setReserveStatus("limit");
				} else {
					setReserveStatus("error");
				}
			});
		}
	}

	return (
		<div className="market-cart">
			<div className="cart-group">
				<h2>single squares</h2>
				{cartSingles && cartSingles.length > 0 && !loading ? (
					<>
						<div className="cart-list">
							{cartSingles.map((square) => {
								return (
									<div className={`market-item ${removing.includes(square.id) ? "removing" : ""}`} key={square.id}>
										<h3>square #{square.id}</h3>
										<div className="market-cart-info">
											<span>row: {square.row}</span>
											<span>column: {square.column}</span>
										</div>
										<div className="half-btns">
											<button className="profile-btn" onClick={() => removeFromCartSingle(square.id)}>
												remove
											</button>
											<div className="half-btn-spacer"></div>
											<button
												className="profile-btn"
												onClick={() => {
													setShowReserveModal(true);
													setReserveData(square);
												}}
											>
												reserve
											</button>
										</div>
									</div>
								);
							})}
						</div>
					</>
				) : (
					<>{!loading && <p className="empty-cart">you dont have any squares in your cart</p>}</>
				)}
			</div>

			<div className="cart-group">
				<h2>range of squares</h2>
				{cartRanges && cartRanges.length > 0 && !loading ? (
					<>
						<div className="cart-list">
							{cartRanges.map((range) => {
								return (
									<div className={`market-item ${removing.includes(range.id) ? "removing" : ""}`} key={range.id}>
										<h3>total squares: {range.total}</h3>
										<div className="market-cart-info">
											<span>starting row: {range.start_row}</span>
											<span>ending row: {range.end_row}</span>
										</div>
										<div className="market-cart-info">
											<span>starting column: {range.start_column}</span>
											<span>ending column: {range.end_column}</span>
										</div>
										<div className="half-btns">
											<button className="profile-btn" onClick={() => removeFromCartRange(range.id)}>
												remove
											</button>
											<div className="half-btn-spacer"></div>
											<button
												className="profile-btn"
												onClick={() => {
													setShowReserveModal(true);
													setReserveData(range);
												}}
											>
												reserve
											</button>
										</div>
									</div>
								);
							})}
						</div>
					</>
				) : (
					<>{!loading && <p className="empty-cart">you dont have any range of squares in your cart</p>}</>
				)}
			</div>

			{loading && <div className="spinner"></div>}
			<Modal showModal={showReserveModal} setShowModal={setShowReserveModal}>
				<div className="reserve-modal">
					<h2>{determineReservationMessage()}</h2>
					<h2 style={{ color: "var(--Error)" }}>
						Please read the following for important information regarding the reservation process.
					</h2>
					<p>
						- The reservation feature is only planned for the 'alpha' edition of this application. By reserving these squares
						you will be able to begin changing the color of the squares that you reserve as soon as you reserve them. 48 hours
						prior to release of our beta ediition you will recieve an early access opportunity to purchase these squares as
						your own. If you do not purchase the squares within the early access period they will be released to the public.
					</p>
					<p>
						- Reminder that by reserving these squares you are not purchasing them, you are simply reserving the right to
						purchase them, and demonstrating intention to buy them.
					</p>
					<p>
						- You can only reserve up to 100 squares at this time. If you would like to reserve more than 100 squares please
						contact us at support@pyxis-grid.com
					</p>
					<div className="half-btns">
						<button className="profile-btn" onClick={() => setShowReserveModal(false)}>
							cancel
						</button>
						<button className="profile-btn" onClick={handleReservingSquares}>
							reserve
						</button>
					</div>
					{reserveStatus === "pending" && <div className="spinner"></div>}
					{reserveStatus === "success" && <p className="success">Squares reserved successfully!</p>}
					{reserveStatus === "error" && <p className="error">Error reserving squares. Please try again later.</p>}
					{reserveStatus === "taken" && <p className="error">Squares have already been reserved.</p>}
					{reserveStatus === "limit" && (
						<p className="error">
							You have reached the limit of 100 squares or reserving these will push you over the limit.
						</p>
					)}
				</div>
			</Modal>
		</div>
	);
}

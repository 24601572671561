import { useAuth } from "../../Components/Auth/CustomAuthHook";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./PageWrapper.css";

export default function PageWrapper({ children }) {
	const location = useLocation();
	const path = location.pathname;
	const { loading, user } = useAuth();
	const [showHeaderIcons, setShowHeaderIcons] = useState(true);

	useEffect(() => {
		switch (path) {
			case "/verify":
			case "/verification":
			case "/login":
			case "/register":
				setShowHeaderIcons(false);
				break;
			default:
				setShowHeaderIcons(true);
		}
	}, [path]);

	if (loading) {
		return (
			<div className="App">
				<div className="spinner"></div>
			</div>
		);
	}

	return (
		<div className="fade-in" key={location.pathname} style={{ position: "relative" }}>
			<Header user={user} showHeaderIcons={showHeaderIcons} />
			<div className="body-container">{children}</div>
			<Footer />
		</div>
	);
}

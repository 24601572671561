import { useCallback, useEffect, useRef, useState } from "react";
import { fetchSquareData } from "../../../Api/square";
import OpenSeadragon from "openseadragon";

export function useRegularOsdCanvas({ setSquareData, setSquareDataLoading }) {
	const [isLoading, setIsLoading] = useState(true); //loader toggle state
	const [isFullScreen, setIsFullScreen] = useState(false); //bool to determine if in full screen or not
	const [showError, setShowError] = useState(false);
	const viewerContainerRef = useRef(null); //canvas container ref
	const viewerRef = useRef(null); //acutal canvas ref
	const isFullScreenRef = useRef(isFullScreen); //true value of fullscreen ref
	const screenWidth = window.innerWidth;
	const maxZoomLevel = screenWidth <= 600 ? 100 : 20;

	useEffect(() => {
		isFullScreenRef.current = isFullScreen;
	}, [isFullScreen]);

	const applyOwnedOverlays = useCallback((squares) => {
		if (!viewerRef.current) {
			console.log("viewerRef.current is not initialized yet");
			return;
		}
		const squareSizeInViewportCoordinates = 0.001;

		squares.forEach((square) => {
			const { column, row } = square;

			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-green");
			overlayElement.classList.add("overlay-visible");

			const overlayLocation = new OpenSeadragon.Rect(
				column * squareSizeInViewportCoordinates,
				row * squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});
	}, []);

	const applyOverlays = useCallback((squares, ranges) => {
		if (!viewerRef.current) {
			console.log("viewerRef.current is not initialized yet");
			return;
		}
		const squareSizeInViewportCoordinates = 0.001;

		squares.forEach((square) => {
			const { column, row } = square;

			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-red");
			overlayElement.classList.add("overlay-visible");

			const overlayLocation = new OpenSeadragon.Rect(
				column * squareSizeInViewportCoordinates,
				row * squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});

		ranges.forEach((range) => {
			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-red");
			overlayElement.classList.add("overlay-visible");

			const overlayWidth = (range.end_column - range.start_column + 1) * squareSizeInViewportCoordinates;
			const overlayHeight = (range.end_row - range.start_row + 1) * squareSizeInViewportCoordinates;

			const overlayLocation = new OpenSeadragon.Rect(
				range.start_column * squareSizeInViewportCoordinates,
				range.start_row * squareSizeInViewportCoordinates,
				overlayWidth,
				overlayHeight
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});
	}, []);

	/*if a square is clicked calculate x and y, fetch data for the square, and add an overlay
	 **********************************************************************************************/
	const handleClick = useCallback(
		(event) => {
			if (!isFullScreenRef.current) {
				const webPoint = new OpenSeadragon.Point(event.position.x, event.position.y);
				const viewportPoint = viewerRef.current.viewport.pointFromPixel(webPoint);
				const imageRect = viewerRef.current.viewport.imageToViewportRectangle(
					0,
					0,
					viewerRef.current.source.width,
					viewerRef.current.source.height
				);

				if (imageRect.containsPoint(viewportPoint)) {
					const squareSizeInViewportCoordinates = 0.001;

					const squareX = Math.floor(viewportPoint.x / squareSizeInViewportCoordinates);
					const squareY = Math.floor(viewportPoint.y / squareSizeInViewportCoordinates);

					// remove existing overlay
					const existingOverlay = document.querySelector(".overlay");
					if (existingOverlay) {
						existingOverlay.parentNode.removeChild(existingOverlay);
						viewerRef.current.removeOverlay(existingOverlay);
					}

					// create and add new overlay
					const overlayElement = document.createElement("div");
					setTimeout(() => {
						overlayElement.classList.add("overlay");
					}, 500);

					const overlayLocation = new OpenSeadragon.Rect(
						squareX * squareSizeInViewportCoordinates,
						squareY * squareSizeInViewportCoordinates,
						squareSizeInViewportCoordinates,
						squareSizeInViewportCoordinates
					);
					viewerRef.current.addOverlay({
						element: overlayElement,
						location: overlayLocation,
					});

					setSquareData(null);
					setSquareDataLoading(true);
					fetchSquareData(squareX, squareY, setSquareData);
				}
			}
		},
		[setSquareData, setSquareDataLoading]
	);

	/*zoom into a particular square
	 **********************************************************************************************/
	const zoomToSquare = useCallback(
		(squareY, squareX) => {
			if (!viewerRef.current) {
				console.log("viewerRef.current is not initialized yet");
				return;
			}
			const squareSizeInViewportCoordinates = 0.001;
			const centerPoint = new OpenSeadragon.Point(
				squareX * squareSizeInViewportCoordinates,
				squareY * squareSizeInViewportCoordinates
			);

			viewerRef.current.viewport.panTo(centerPoint, true);
			viewerRef.current.viewport.zoomTo(maxZoomLevel, null, true);

			// After zooming in, simulate a click event on the center of the square
			const simulatedEvent = {
				position: viewerRef.current.viewport.pixelFromPoint(centerPoint),
			};
			handleClick(simulatedEvent);
		},
		[handleClick, maxZoomLevel]
	);

	useEffect(() => {
		/*openseadragon settings and fetch for canvas
		 **********************************************************************************************/
		if (viewerContainerRef.current) {
			const screenWidth = window.innerWidth;
			const maxZoomLevel = screenWidth <= 600 ? 100 : 20;

			viewerRef.current = OpenSeadragon({
				element: viewerContainerRef.current,
				prefixUrl: "//openseadragon.github.io/openseadragon/images/",
				showNavigationControl: false,
				tileSources: {
					type: "image",
					url: "https://api.pyxisgrid.com/grid/get_grid",
				},
				minZoomImageRatio: 0.5,
				maxZoomPixelRatio: 20,
				defaultZoomLevel: 0,
				minZoomLevel: 0.25,
				maxZoomLevel: maxZoomLevel,
				clickToZoom: false,
				zoomPerClick: 1,
			});

			//loader toggle
			viewerRef.current.addHandler("tile-loaded", () => {
				if (viewerRef.current.isOpen()) {
					setIsLoading(false);
				}
			});

			viewerRef.current.addHandler("open-failed", function (event) {
				console.error("Failed to open OpenSeadragon", event);
				setShowError(true);
			});
		}

		/*logic to add functionality for custom buttons
		 **********************************************************************************************/
		const zoomInButton = document.getElementById("zoom-in");
		const zoomOutButton = document.getElementById("zoom-out");
		const homeButton = document.getElementById("home");
		const fullscreenButton = document.getElementById("full-page");

		const zoomInHandler = function () {
			//match avaliable zoom distance to maxZoomLevel
			let nextZoom = viewerRef.current.viewport.getZoom() * 2;
			if (nextZoom > viewerRef.current.maxZoomLevel) {
				nextZoom = viewerRef.current.maxZoomLevel;
			}
			viewerRef.current.viewport.zoomTo(nextZoom);
		};

		const zoomOutHandler = function () {
			//match avaliable zoom distance to minZoomLevel
			let nextZoom = viewerRef.current.viewport.getZoom() * 0.5;
			if (nextZoom < viewerRef.current.minZoomLevel) {
				nextZoom = viewerRef.current.minZoomLevel;
			}
			viewerRef.current.viewport.zoomTo(nextZoom);
		};

		const homeHandler = function () {
			viewerRef.current.viewport.goHome();
		};

		const fullscreenHandler = function () {
			//determine if were fullscreen or not
			const isFullPage = viewerRef.current.isFullPage();
			viewerRef.current.setFullScreen(!isFullPage);
			setIsFullScreen(!isFullPage);
		};

		zoomInButton.addEventListener("click", zoomInHandler);
		zoomOutButton.addEventListener("click", zoomOutHandler);
		homeButton.addEventListener("click", homeHandler);
		fullscreenButton.addEventListener("click", fullscreenHandler);

		/*ensure that a square is clicked and the canvas isnt dragged
		 **********************************************************************************************/
		let pressPoint = null;

		viewerRef.current.addHandler("canvas-press", function (event) {
			pressPoint = new OpenSeadragon.Point(event.position.x, event.position.y);
		});

		viewerRef.current.addHandler("canvas-release", function (event) {
			const releasePoint = new OpenSeadragon.Point(event.position.x, event.position.y);
			if (pressPoint.distanceTo(releasePoint) < 5 && !isFullScreen) {
				handleClick(event);
			}
			pressPoint = null;
		});

		/*fallback on dismount
        /**********************************************************************************************/
		return () => {
			if (viewerRef.current) {
				viewerRef.current.destroy();
				viewerRef.current = null;
			}
			zoomInButton.removeEventListener("click", zoomInHandler);
			zoomOutButton.removeEventListener("click", zoomOutHandler);
			homeButton.removeEventListener("click", homeHandler);
			fullscreenButton.removeEventListener("click", fullscreenHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoomToSquare, handleClick]);

	return {
		zoomToSquare,
		viewerContainerRef,
		isLoading,
		isFullScreen,
		showError,
		applyOwnedOverlays,
		applyOverlays,
	};
}

import { Figura, FiguraHidden, FiguraLabel, FiguraSubmitBtn, FiguraText } from "react-figura";
import { updateReplaceUsername } from "../../../Api/user";
import { useState } from "react";
import "./UpdateUsername.css";

export default function UpdateUsername() {
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState({ bool: false, message: "" });

	async function updateUsernameAddress({ oldUsername, newUsername }) {
		console.log(oldUsername, newUsername);
		setShowError({ bool: false, message: "" });
		setShowSuccess(false);
		if (oldUsername && newUsername) {
			try {
				const response = await updateReplaceUsername(oldUsername, newUsername);
				if (response.message) {
					if (response.message === "User not found") {
						setShowError({ bool: true, message: "username not found" });
					} else {
						setShowSuccess(true);
					}
				}
			} catch (error) {
				setShowError({ bool: true, message: "issue updating username" });
				console.log(error);
			}
		}
	}

	return (
		<div className="update-username">
			<h1>update username</h1>
			<br />
			<Figura formID="updateusername" formStyle="figura-form figura-modal" onSubmit={updateUsernameAddress}>
				<FiguraText name="oldUsername" inputStyle="figura-input" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">old username:</FiguraLabel>
				</FiguraText>
				<FiguraText name="newUsername" inputStyle="figura-input" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">new username:</FiguraLabel>
				</FiguraText>
				<FiguraHidden />
				<FiguraSubmitBtn buttonStyle="figura-button figura-modal">submit</FiguraSubmitBtn>
			</Figura>
			{showSuccess && (
				<p className="success">email successfully updated, we sent a verification email to your new address.</p>
			)}
			{showError.bool && <p className="warning-red">{showError.message}</p>}
		</div>
	);
}

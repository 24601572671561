import TermsAndConditions from "../../Helpers/TermsAndConditions.json";
import ReactQuill from "react-quill";
import "./Terms.css";

export default function Terms() {
	return (
		<div className="legals">
			<ReactQuill value={TermsAndConditions} readOnly={true} modules={{ toolbar: false }} />
		</div>
	);
}

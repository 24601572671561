import { useState } from "react";
import "./Footer.css";
import { FaFacebook, FaInstagram, FaRedditAlien, FaInfoCircle } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Modal from "../../Layouts/Modal/Modal";

export default function Footer() {
	const seenInfoModal = localStorage.getItem("seenInfoModal");
	const [showInfoModal, setShowInfoModal] = useState(seenInfoModal === null ? true : false);

	return (
		<>
			<div className="footer">
				<a className="indiv-icons" href="https://twitter.com/pyxisgrid" target="_blank" rel="noopener noreferrer">
					<FaXTwitter size="25px" />
				</a>
				<a className="indiv-icons" href="https://www.reddit.com/r/pyxisgrid/" target="_blank" rel="noopener noreferrer">
					<FaRedditAlien size="25px" />
				</a>
				<a className="indiv-icons" href="https://twitter.com/pyxisgrid">
					<FaFacebook size="25px" />
				</a>
				<a className="indiv-icons" href="https://twitter.com/pyxisgrid">
					<FaInstagram size="25px" />
				</a>
				<a className="indiv-icons" href="mailto:support@pyxisgrid.com" rel="noopener noreferrer">
					<MdEmail size="25px" />
				</a>
				<div className="indiv-icons" onClick={() => setShowInfoModal(true)}>
					<FaInfoCircle size="25px" />
				</div>
			</div>
			<Modal showModal={showInfoModal} setShowModal={setShowInfoModal}>
				<div className="info-modal">
					<h1>Pyxis Info</h1>
					<p>
						Pyxis is a community-driven marketplace. We are a platform inspired by reddits r/place and the million dollar
						homepage. The twist is that you can buy and sell squares on our grid. This will create an ecosystem that provides
						a unique investment opportunity, and the opportunity to create incredible pixel art.
					</p>
					<p>
						We are currently in the alpha stage of development, and as such you will notice some features are'nt yet
						avaliable. In the alpha stage you can reserve up to 100 squares and begin changing there colors at no cost. The
						squares that you reserve will give you temporary ownership, 48 hours prior to our beta release you will be given
						the opportunity to purchase those squares. Once beta releases any un-owned squares will be released to the public.
						We are working hard to bring you the best possible experience.
					</p>
					<p>For any questions or concerns, please email us at support@pyxisgrid.com</p>
					<p>
						You can open this panel again by clicking the <FaInfoCircle /> icon at the bottom of any page.
					</p>
					<button
						className="profile-btn"
						onClick={() => {
							localStorage.setItem("seenInfoModal", "true");
							setShowInfoModal(false);
						}}
					>
						got it!
					</button>
				</div>
			</Modal>
		</>
	);
}

import { AiOutlineCloseCircle } from "react-icons/ai";
import ColorPicker from "./ColorPicker/ColorPicker";
import { addSquareToCart } from "../../../Api/cart";
import { useEffect, useState } from "react";
import { auth } from "../../Auth/Firebase";
import "./SquareInfo.css";
import { getUserId } from "../../../Api/user";

export default function SquareInfo({
	squareDataLoading,
	setSquareDataLoading,
	squareData,
	setSquareData,
	updateCartCount,
}) {
	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		if (auth.currentUser) {
			getUserId(auth.currentUser.email).then((res) => {
				setUserId(res);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.currentUser]);

	function openColorPicker() {
		setIsColorPickerOpen(true);
	}

	function closeSquareInfo() {
		setSquareData(null);
		setIsColorPickerOpen(false);
	}

	function addToCart() {
		setError(false);
		setSuccess(false);
		const userEmail = auth.currentUser ? auth.currentUser.email : null;
		addSquareToCart(squareData, userEmail).then((res) => {
			if (res.status === 409) {
				setError(true);
			} else {
				updateCartCount();
				setSuccess(true);
			}
		});
	}

	useEffect(() => {
		setError(false);
		setSuccess(false);
		if (squareData) {
			setSquareDataLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [squareData]);

	return (
		<>
			{squareData && (
				<div className="square-info">
					<AiOutlineCloseCircle size="16px" className="close-icon" onClick={closeSquareInfo} />
					<h1 style={{ color: userId === squareData.owner ? "var(--Success)" : "var(--White)" }}>
						{userId === squareData.owner && "your"} square
					</h1>
					<p>number: {squareData.id}</p>
					<p>owner: {squareData.owner}</p>
					<p>color: {squareData.color}</p>
					<p>row: {squareData.row}</p>
					<p>column: {squareData.column}</p>
					{auth.currentUser ? (
						<>
							{userId === squareData.owner ? (
								<button onClick={openColorPicker}>color</button>
							) : (
								<>
									{squareData.owner === 0 ? (
										<button className={`${error && "square-error-btn"}`} onClick={addToCart}>
											add to cart
										</button>
									) : (
										<>
											<p className="square-error">This square is</p>
											<p className="square-error">already reserved.</p>
										</>
									)}
								</>
							)}
						</>
					) : (
						<p className="square-error">log in to reserve a square</p>
					)}
					{error && <p className="square-error">already in cart</p>}
					{success && <p className="square-success">added to cart!</p>}
				</div>
			)}
			{squareDataLoading && !squareData ? (
				<div className="square-info">
					<div className="spinner-container">
						<div className="small-spinner"></div>
					</div>
				</div>
			) : (
				""
			)}
			{isColorPickerOpen && (
				<ColorPicker squareData={squareData} setIsColorPickerOpen={setIsColorPickerOpen} setSquareData={setSquareData} />
			)}
		</>
	);
}

import { useCallback, useEffect, useRef, useState } from "react";
import OpenSeadragon from "openseadragon";

export function useMultiOsdCanvas() {
	const [isLoading, setIsLoading] = useState(true); //loader toggle state
	const [selectedSquaresArray, setSelectedSquaresArray] = useState([]);
	const [showError, setShowError] = useState(false);
	const viewerContainerRef = useRef(null); //canvas container ref
	const viewerRef = useRef(null); //acutal canvas ref
	const screenWidth = window.innerWidth;
	const maxZoomLevel = screenWidth <= 600 ? 100 : 20;

	const applyOwnedOverlays = useCallback((squares) => {
		if (!viewerRef.current) {
			console.log("viewerRef.current is not initialized yet");
			return;
		}
		const squareSizeInViewportCoordinates = 0.001;

		squares.forEach((square) => {
			const { column, row } = square;

			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-green");
			overlayElement.classList.add("overlay-visible");

			const overlayLocation = new OpenSeadragon.Rect(
				column * squareSizeInViewportCoordinates,
				row * squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});
	}, []);

	const applyOverlays = useCallback((squares, ranges) => {
		if (!viewerRef.current) {
			console.log("viewerRef.current is not initialized yet");
			return;
		}
		const squareSizeInViewportCoordinates = 0.001;

		squares.forEach((square) => {
			const { column, row } = square;

			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-red");
			overlayElement.classList.add("overlay-visible");

			const overlayLocation = new OpenSeadragon.Rect(
				column * squareSizeInViewportCoordinates,
				row * squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates,
				squareSizeInViewportCoordinates
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});

		ranges.forEach((range) => {
			const overlayElement = document.createElement("div");
			overlayElement.classList.add("overlay-red");
			overlayElement.classList.add("overlay-visible");

			const overlayWidth = (range.end_column - range.start_column + 1) * squareSizeInViewportCoordinates;
			const overlayHeight = (range.end_row - range.start_row + 1) * squareSizeInViewportCoordinates;

			const overlayLocation = new OpenSeadragon.Rect(
				range.start_column * squareSizeInViewportCoordinates,
				range.start_row * squareSizeInViewportCoordinates,
				overlayWidth,
				overlayHeight
			);
			viewerRef.current.addOverlay({
				element: overlayElement,
				location: overlayLocation,
			});
		});
	}, []);

	/*zoom into a particular square
	 **********************************************************************************************/
	const zoomToSquare = useCallback(
		(squareY, squareX) => {
			if (!viewerRef.current) {
				console.log("viewerRef.current is not initialized yet");
				return;
			}
			const squareSizeInViewportCoordinates = 0.001;
			const centerPoint = new OpenSeadragon.Point(
				squareX * squareSizeInViewportCoordinates,
				squareY * squareSizeInViewportCoordinates
			);

			viewerRef.current.viewport.panTo(centerPoint, true);
			viewerRef.current.viewport.zoomTo(maxZoomLevel, null, true);
		},
		[maxZoomLevel]
	);

	useEffect(() => {
		/*openseadragon settings and fetch for canvas
		 **********************************************************************************************/
		if (viewerContainerRef.current) {
			const screenWidth = window.innerWidth;
			const maxZoomLevel = screenWidth <= 600 ? 100 : 20;

			viewerRef.current = OpenSeadragon({
				element: viewerContainerRef.current,
				prefixUrl: "//openseadragon.github.io/openseadragon/images/",
				showNavigationControl: false,
				tileSources: {
					type: "image",
					url: "https://api.pyxisgrid.com/grid/get_grid",
				},
				minZoomImageRatio: 0.5,
				maxZoomPixelRatio: 20,
				defaultZoomLevel: 0,
				minZoomLevel: 0.25,
				maxZoomLevel: maxZoomLevel,
				clickToZoom: false,
				panHorizontal: false,
				panVertical: false,
				zoomPerClick: 1,
			});

			//loader toggle
			viewerRef.current.addHandler("tile-loaded", () => {
				if (viewerRef.current.isOpen()) {
					setIsLoading(false);
				}
			});

			viewerRef.current.addHandler("open-failed", function (event) {
				console.error("Failed to open OpenSeadragon", event);
				setShowError(true);
			});
		}

		/*logic to add functionality for custom buttons
		 **********************************************************************************************/
		const zoomInButton = document.getElementById("zoom-in");
		const zoomOutButton = document.getElementById("zoom-out");
		const homeButton = document.getElementById("home");

		const zoomInHandler = function () {
			//match avaliable zoom distance to maxZoomLevel
			let nextZoom = viewerRef.current.viewport.getZoom() * 2;
			if (nextZoom > viewerRef.current.maxZoomLevel) {
				nextZoom = viewerRef.current.maxZoomLevel;
			}
			viewerRef.current.viewport.zoomTo(nextZoom);
		};

		const zoomOutHandler = function () {
			//match avaliable zoom distance to minZoomLevel
			let nextZoom = viewerRef.current.viewport.getZoom() * 0.5;
			if (nextZoom < viewerRef.current.minZoomLevel) {
				nextZoom = viewerRef.current.minZoomLevel;
			}
			viewerRef.current.viewport.zoomTo(nextZoom);
		};

		const homeHandler = function () {
			viewerRef.current.viewport.goHome();
		};

		zoomInButton.addEventListener("click", zoomInHandler);
		zoomOutButton.addEventListener("click", zoomOutHandler);
		homeButton.addEventListener("click", homeHandler);

		/*ensure that a square is clicked and the canvas isnt dragged
		 **********************************************************************************************/
		let dragStartPoint = null;
		let selectedSquares = [];

		viewerRef.current.addHandler("canvas-press", function (event) {
			const webPoint = new OpenSeadragon.Point(event.position.x, event.position.y);
			const viewportPoint = viewerRef.current.viewport.pointFromPixel(webPoint);
			const imageRect = viewerRef.current.viewport.imageToViewportRectangle(
				0,
				0,
				viewerRef.current.source.width,
				viewerRef.current.source.height
			);

			if (imageRect.containsPoint(viewportPoint)) {
				dragStartPoint = viewportPoint;
			} else {
				dragStartPoint = null;
			}
		});

		viewerRef.current.addHandler("canvas-drag", function (event) {
			if (!dragStartPoint) return;
			setSelectedSquaresArray([]);
			const webPoint = new OpenSeadragon.Point(event.position.x, event.position.y);
			const viewportPoint = viewerRef.current.viewport.pointFromPixel(webPoint);
			const imageRect = viewerRef.current.viewport.imageToViewportRectangle(
				0,
				0,
				viewerRef.current.source.width,
				viewerRef.current.source.height
			);

			if (imageRect.containsPoint(viewportPoint)) {
				const squareSizeInViewportCoordinates = 0.001;
				const x1 = Math.min(viewportPoint.x, dragStartPoint.x);
				const x2 = Math.max(viewportPoint.x, dragStartPoint.x);
				const y1 = Math.min(viewportPoint.y, dragStartPoint.y);
				const y2 = Math.max(viewportPoint.y, dragStartPoint.y);

				const squareX1 = Math.floor(x1 / squareSizeInViewportCoordinates);
				const squareX2 = Math.floor(x2 / squareSizeInViewportCoordinates);
				const squareY1 = Math.floor(y1 / squareSizeInViewportCoordinates);
				const squareY2 = Math.floor(y2 / squareSizeInViewportCoordinates);

				// remove existing overlay
				const existingOverlay = document.querySelector(".overlay");
				if (existingOverlay) {
					existingOverlay.parentNode.removeChild(existingOverlay);
					viewerRef.current.removeOverlay(existingOverlay);
				}

				selectedSquares = [];

				const overlayElement = document.createElement("div");
				overlayElement.classList.add("overlay");

				const overlayLocation = new OpenSeadragon.Rect(
					squareX1 * squareSizeInViewportCoordinates,
					squareY1 * squareSizeInViewportCoordinates,
					(squareX2 - squareX1 + 1) * squareSizeInViewportCoordinates,
					(squareY2 - squareY1 + 1) * squareSizeInViewportCoordinates
				);
				viewerRef.current.addOverlay({
					element: overlayElement,
					location: overlayLocation,
				});

				for (let x = squareX1; x <= squareX2; x++) {
					for (let y = squareY1; y <= squareY2; y++) {
						selectedSquares.push({ row: y, column: x });
					}
				}
			} else {
				viewerRef.current.clearOverlays();
				selectedSquares = [];
				dragStartPoint = null;
			}
		});

		viewerRef.current.addHandler("canvas-drag-end", function (event) {
			const webPoint = new OpenSeadragon.Point(event.position.x, event.position.y);
			const viewportPoint = viewerRef.current.viewport.pointFromPixel(webPoint);
			const imageRect = viewerRef.current.viewport.imageToViewportRectangle(
				0,
				0,
				viewerRef.current.source.width,
				viewerRef.current.source.height
			);

			if (imageRect.containsPoint(viewportPoint)) {
				dragStartPoint = null;
				setSelectedSquaresArray(selectedSquares);
			} else {
				viewerRef.current.clearOverlays();
				selectedSquares = [];
				dragStartPoint = null;
			}
		});

		/*fallback on dismount
        /**********************************************************************************************/
		return () => {
			if (viewerRef.current) {
				viewerRef.current.destroy();
				viewerRef.current = null;
			}
			zoomInButton.removeEventListener("click", zoomInHandler);
			zoomOutButton.removeEventListener("click", zoomOutHandler);
			homeButton.removeEventListener("click", homeHandler);
		};
	}, [zoomToSquare]);

	return {
		zoomToSquare,
		viewerContainerRef,
		isLoading,
		selectedSquaresArray,
		showError,
		applyOverlays,
		applyOwnedOverlays,
	};
}

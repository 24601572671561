import { TbSearchOff } from "react-icons/tb";
import { useState } from "react";
import "./Search.css";

export default function Search({ zoomToSquare, setIsSearchOpen }) {
	const [showError, setShowError] = useState(false);

	function submitSquareZoom(e) {
		e.preventDefault();
		const row = e.target[0].value;
		const column = e.target[1].value;
		if (isNaN(row) || row < 0 || row > 999 || isNaN(column) || column < 0 || column > 999) {
			setShowError(true);
		} else {
			zoomToSquare(row, column);
		}
	}

	return (
		<div className="search-open">
			<button className="search-icon-open" onClick={() => setIsSearchOpen(false)}>
				<TbSearchOff size="30px" />
			</button>
			<form onSubmit={submitSquareZoom}>
				<input
					id="row"
					name="row"
					type="text"
					placeholder="row"
					className={`${showError && "input-error"}`}
					onChange={() => {
						showError && setShowError(false);
					}}
				/>
				<input
					id="column"
					name="column"
					type="text"
					placeholder="column"
					className={`${showError && "input-error"}`}
					onChange={() => {
						showError && setShowError(false);
					}}
				/>
				<button className="search-button">search</button>
			</form>
		</div>
	);
}

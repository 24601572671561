import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase";

export function useAuth() {
	const [isVerified, setIsVerified] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
			setUser(currentUser);
			setLoading(false);
			if (currentUser) {
				setIsVerified(currentUser.emailVerified);
			}
		});
		return () => unsubscribe();
	}, []);

	return { loading, user, isVerified };
}

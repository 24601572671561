import { useState } from "react";
import RegularMode from "../Canvas/RegularMode";
import MultiMode from "../Canvas/MultiMode";
import "./Home.css";

export default function Home() {
	const [isMultiSelect, setIsMultiSelect] = useState(false);

	return (
		<div className="homeContainer">
			{!isMultiSelect ? (
				<RegularMode setIsMultiSelect={setIsMultiSelect} />
			) : (
				<MultiMode setIsMultiSelect={setIsMultiSelect} />
			)}
		</div>
	);
}

import PyxisPolicy from "../../Helpers/PyxisPolicy.json";
import ReactQuill from "react-quill";

export default function Policy() {
	return (
		<div className="legals">
			<ReactQuill value={PyxisPolicy} readOnly={true} modules={{ toolbar: false }} />
		</div>
	);
}

import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import Tooltip from "../../../Layouts/ToolTip/ToolTip";
import { TbReload } from "react-icons/tb";
import "./ToolbarOsd.css";

export default function ToolbarOsd({ isFullScreen, isMultiSelect }) {
	return (
		<div id="toolbar" className="toolbar">
			<Tooltip text="zoom in" position="right">
				<button id="zoom-in">
					<FiZoomIn size="30px" />
				</button>
			</Tooltip>
			<Tooltip text="zoom out" position="right">
				<button id="zoom-out">
					<FiZoomOut size="30px" />
				</button>
			</Tooltip>
			<Tooltip text="reset" position="right">
				<button id="home">
					<TbReload size="30px" />
				</button>
			</Tooltip>
			{!isMultiSelect && (
				<Tooltip text="fullscreen" position="right">
					<button id="full-page">
						{isFullScreen ? <AiOutlineFullscreenExit size="30px" /> : <AiOutlineFullscreen size="30px" />}
					</button>
				</Tooltip>
			)}
		</div>
	);
}

import { BiSelectMultiple } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import "./BigToggleButton.css";
import Tooltip from "../../../Layouts/ToolTip/ToolTip";

export default function BigToggleButton({ isMultiSelect, setIsMultiSelect }) {
	return (
		<div className="big-toggle">
			{!isMultiSelect ? (
				<Tooltip text="select multiple mode" position="left">
					<button onClick={() => setIsMultiSelect(true)}>
						<BiSelectMultiple size="40px" />
					</button>
				</Tooltip>
			) : (
				<Tooltip text="exit multiple mode" position="left">
					<button onClick={() => setIsMultiSelect(false)}>
						<CgClose size="45px" />
					</button>
				</Tooltip>
			)}
		</div>
	);
}

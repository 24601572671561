import { useState } from "react";

export function useDashboardModals() {
	const [showFeedback, setShowFeedback] = useState(false);
	const [showDeleteAccount, setShowDeleteAccount] = useState(false);
	const [showUpdateEmail, setShowUpdateEmail] = useState(false);
	const [showUpdateUsername, setShowUpdateUsername] = useState(false);

	function openFeedback() {
		setShowFeedback(true);
	}

	function openDelectAccount() {
		setShowDeleteAccount(true);
	}

	function openUpdateEmail() {
		setShowUpdateEmail(true);
	}

	function openUpdateUsername() {
		setShowUpdateUsername(true);
	}

	return {
		showFeedback,
		showDeleteAccount,
		showUpdateEmail,
		showUpdateUsername,
		openFeedback,
		openDelectAccount,
		openUpdateEmail,
		openUpdateUsername,
		setShowFeedback,
		setShowDeleteAccount,
		setShowUpdateEmail,
		setShowUpdateUsername,
	};
}

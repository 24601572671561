import "./Test.css";

export default function Test() {
	return (
		<div className="test">
			<h1>p</h1>
			<h2>pyxis</h2>
		</div>
	);
}

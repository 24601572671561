import { PiSelectionForegroundBold } from "react-icons/pi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Search from "../Search/Search";
import "./ToolbarUtil.css";
import { TbSearch } from "react-icons/tb";
import { useState } from "react";
import Tooltip from "../../../Layouts/ToolTip/ToolTip";
import { FaRegCheckSquare } from "react-icons/fa";

export default function ToolbarUtil({ isFullScreen, cartCount, zoomToSquare }) {
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const navigate = useNavigate();
	const goMarket = () => {
		navigate("/market");
	};

	function toggleCartOverlays(type) {
		const thisOverlay = type === "owned" ? ".overlay-green" : ".overlay-red";
		const cartOverlays = document.querySelectorAll(thisOverlay);
		cartOverlays &&
			cartOverlays.forEach((overlay) => {
				if (overlay.classList.contains("overlay-visible")) {
					overlay.classList.remove("overlay-visible");
					overlay.classList.add("overlay-hidden");
				} else {
					overlay.classList.remove("overlay-hidden");
					overlay.classList.add("overlay-visible");
				}
			});
	}

	return (
		<>
			{!isFullScreen && (
				<div className="toolbar-osd">
					{cartCount && cartCount.total > 0 ? (
						<div className="cart-count">
							<h5>{cartCount.total}</h5>
						</div>
					) : (
						<div />
					)}
					<Tooltip text="your cart" position="right">
						<button onClick={goMarket}>
							<AiOutlineShoppingCart size="26px" />
						</button>
					</Tooltip>
					<Tooltip text="toggle owned squares overlays" position="right">
						<button onClick={() => toggleCartOverlays("owned")}>
							<FaRegCheckSquare size="24px" />
						</button>
					</Tooltip>
					<Tooltip text="toggle cart overlays" position="right">
						<button onClick={() => toggleCartOverlays("cart")}>
							<PiSelectionForegroundBold size="26px" />
						</button>
					</Tooltip>
					{!isSearchOpen ? (
						<Tooltip text="search" position="right">
							<button onClick={() => setIsSearchOpen(true)}>
								<TbSearch size="30px" />
							</button>
						</Tooltip>
					) : (
						<Search zoomToSquare={zoomToSquare} setIsSearchOpen={setIsSearchOpen} />
					)}
				</div>
			)}
		</>
	);
}

const API_URL = "https://api.pyxisgrid.com";

export function addSquareToCart(square, email) {
	return fetch(`${API_URL}/cart/add_square`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ square, email }),
	}).catch((error) => console.error("Error:", error.message));
}

export function getCart(email) {
	return fetch(`${API_URL}/cart/get_cart_squares`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function removeSquare(squareID, email) {
	return fetch(`${API_URL}/cart/remove_square`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ squareID, email }),
	}).catch((error) => console.error("Error:", error));
}

export function cartTotal(email) {
	return fetch(`${API_URL}/cart/cart_total`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function addMultipleSquaresToCart(startRow, startColumn, endRow, endColumn, userEmail) {
	return fetch(`${API_URL}/cart/add_range`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ startRow, startColumn, endRow, endColumn, userEmail }),
	}).catch((error) => console.error("Error:", error.message));
}

export function removeRange(rangeID, email) {
	return fetch(`${API_URL}/cart/remove_range`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ rangeID, email }),
	}).catch((error) => console.error("Error:", error));
}

export function getCartRange(email) {
	return fetch(`${API_URL}/cart/get_cart_ranges`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function reserveSquares(squareIds, email) {
	console.log(squareIds, email);
	return fetch(`${API_URL}/cart/reserve_squares`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ squareIds, email }),
	}).catch((error) => console.error("Error:", error));
}

import { cartTotal, getCart, getCartRange } from "../../Api/cart";
import MultiSquareInfo from "./MultiSquareInfo/MultiSquareInfo";
import BigToggleButton from "./BigToggleButton/BigToggleButton";
import { useMultiOsdCanvas } from "./Hooks/CustomMultiOSDHook";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ToolbarUtil from "./Toolbars/ToolbarUtil";
import ToolbarOsd from "./Toolbars/ToolbarOsd";
import { useEffect, useState } from "react";
import { auth } from "../Auth/Firebase";
import { getOwnedSquares } from "../../Api/square";

export default function MultiMode({ setIsMultiSelect }) {
	const [showNotice, setShowNotice] = useState(true);
	const [cartCount, setCartCount] = useState(0);
	const osdCanvas = useMultiOsdCanvas();
	const closeNotice = () => setShowNotice(false);

	useEffect(() => {
		if (auth.currentUser) {
			cartTotal(auth.currentUser.email).then((total) => setCartCount(total));
		}
	}, []);

	useEffect(() => {
		if (auth.currentUser) {
			getOwnedSquares(auth.currentUser.email).then((squares) => {
				osdCanvas.applyOwnedOverlays(squares);
			});

			getCart(auth.currentUser.email).then((singles) => {
				getCartRange(auth.currentUser.email).then((range) => {
					osdCanvas.applyOverlays(singles, range);
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osdCanvas.applyOverlays, cartCount]);

	function updateCartCount() {
		if (auth.currentUser) {
			cartTotal(auth.currentUser.email).then((total) => setCartCount(total));
		}
	}

	if (osdCanvas.showError) {
		return (
			<p className="osd-error">
				There was a problem loading the canvas, please refresh the page or try again later if this issue persists. We apologize for the
				inconvenience.
			</p>
		);
	}

	return (
		<div ref={osdCanvas.viewerContainerRef} className="grid">
			<ToolbarOsd isFullScreen={osdCanvas.isFullScreen} isMultiSelect={true} />

			<ToolbarUtil isFullScreen={osdCanvas.isFullScreen} cartCount={cartCount} zoomToSquare={osdCanvas.zoomToSquare} />

			<BigToggleButton setIsMultiSelect={setIsMultiSelect} isMultiSelect={true} />

			<MultiSquareInfo setShowNotice={setShowNotice} osdCanvas={osdCanvas} updateCartCount={updateCartCount} />

			{osdCanvas.isLoading && <div className="spinner"></div>}

			{showNotice && (
				<div className="square-info multi-info">
					<AiOutlineCloseCircle size="16px" className="close-icon" onClick={closeNotice} />
					<h1>select multiple mode</h1>
					<p>- your currently in the select mode. you can click and drag to select multiple squares.</p>
					<p>- panning around the image, and full screen are disabled while in this mode.</p>
					<p>- to go back into the regular mode just click the big X button at the bottom right.</p>
				</div>
			)}
		</div>
	);
}

const API_URL = "https://api.pyxisgrid.com";

export function fetchSquareData(x, y, callback) {
	fetch(`${API_URL}/square/get_square?x=${x}&y=${y}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	})
		.then((response) => response.json())
		.then((data) => {
			callback(data);
		})
		.catch((error) => console.error("Error:", error));
}

export function changeColor(color, id) {
	return fetch(`${API_URL}/square/color`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ color, id }),
	}).catch((error) => console.error("Error:", error));
}

export function getOwnedSquares(email) {
	return fetch(`${API_URL}/square/get_owned_squares?email=${email}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

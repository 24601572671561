import PrivacyPolicy from "../../Helpers/PrivacyPolicy.json";
import ReactQuill from "react-quill";

export default function Privacy() {
	return (
		<div className="legals">
			<ReactQuill value={PrivacyPolicy} readOnly={true} modules={{ toolbar: false }} />
		</div>
	);
}

const API_URL = "https://api.pyxisgrid.com";

export function addFeedback(email, feedback, type) {
	return fetch(`${API_URL}/feedback/add_feedback`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
		body: JSON.stringify({ email, feedback, type }),
	}).catch((error) => console.error("Error:", error.message));
}

export function getFeedback() {
	return fetch(`${API_URL}/feedback/get_feedback`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

import { AiOutlineCloseCircle } from "react-icons/ai";
import "./Modal.css";

/**
 * @prop children: React.ReactNode
 * @prop showModal: boolean
 * @prop setShowModal: (showModal: boolean) => void
 */

export default function Modal(props) {
	const { children, showModal, setShowModal } = props;

	return (
		<>
			<div className={`lemma-modal-overlay ${showModal ? "lemma-modal-overlay-open" : ""}`}>
				<div className={`lemma-modal ${showModal ? "lemma-modal-open" : ""}`}>
					<AiOutlineCloseCircle size="28px" className="modal-close-icon" onClick={() => setShowModal(false)} />
					{children}
				</div>
			</div>
		</>
	);
}

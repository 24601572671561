import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addMultipleSquaresToCart } from "../../../Api/cart";
import { auth } from "../../Auth/Firebase";

export default function MultiSquareInfo({ setShowNotice, osdCanvas, updateCartCount }) {
	const [limiter, setLimiter] = useState(false);
	const [squarePostPending, setSquarePostPending] = useState(false);
	const [error, setError] = useState({ bool: false, message: "" });
	const [showSqaureData, setShowSquareData] = useState(false);
	const [success, setSuccess] = useState(false);

	const closeSquareData = () => {
		setShowSquareData(false);
		setLimiter(false);
		setSuccess(false);
		setError(false);
	};

	useEffect(() => {
		setLimiter(false);
		setSuccess(false);
		setError(false);
		if (osdCanvas.selectedSquaresArray.length >= 1) {
			setShowNotice(false);
			setShowSquareData(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [osdCanvas.selectedSquaresArray]);

	function postSquares() {
		setSuccess(false);
		setError({ bool: false, message: "" });
		if (osdCanvas.selectedSquaresArray.length > 100) {
			setLimiter(true);
		} else {
			setSquarePostPending(true);
			const userEmail = auth.currentUser ? auth.currentUser.email : null;
			const startRow = osdCanvas.selectedSquaresArray[0].row;
			const startColumn = osdCanvas.selectedSquaresArray[0].column;
			const endRow = osdCanvas.selectedSquaresArray[osdCanvas.selectedSquaresArray.length - 1].row;
			const endColumn = osdCanvas.selectedSquaresArray[osdCanvas.selectedSquaresArray.length - 1].column;
			addMultipleSquaresToCart(startRow, startColumn, endRow, endColumn, userEmail).then((res) => {
				if (res.status === 409) {
					setError({ bool: true, message: "one or more of the squares are already in your cart" });
					setSquarePostPending(false);
				} else if (res.status === 403) {
					setError({ bool: true, message: "one or more of the squares in this range is already reserved" });
					setSquarePostPending(false);
				} else if (res.status !== 200) {
					setError({ bool: true, message: "an error occurred, please try again" });
					setSquarePostPending(false);
				} else {
					updateCartCount();
					setSuccess(true);
					setSquarePostPending(false);
				}
			});
		}
	}

	return (
		<>
			{showSqaureData && osdCanvas.selectedSquaresArray.length >= 1 && (
				<div className="square-info multi-info">
					<>
						<AiOutlineCloseCircle size="16px" className="close-icon" onClick={closeSquareData} />
						<h1>squares</h1>
						<p>starting row: {osdCanvas.selectedSquaresArray[0].row}</p>
						<p>starting column: {osdCanvas.selectedSquaresArray[0].column}</p>
						<p>ending row: {osdCanvas.selectedSquaresArray[osdCanvas.selectedSquaresArray.length - 1].row}</p>
						<p>ending column: {osdCanvas.selectedSquaresArray[osdCanvas.selectedSquaresArray.length - 1].column}</p>
						<p>total squares: {osdCanvas.selectedSquaresArray.length}</p>
						{auth.currentUser ? (
							<button onClick={postSquares} disabled={squarePostPending}>
								{squarePostPending ? "adding..." : "add to cart"}
							</button>
						) : (
							<p className="square-error">log in to reserve squares</p>
						)}
						{error.bool && <p className="square-error">{error.message}</p>}
						{limiter && <p className="square-error">you can only add 100 squares to your cart at a time</p>}
						{success && <p className="square-success">added to cart!</p>}
						{squarePostPending && (
							<div className="spinner-container multi-spinner-container">
								<div className="small-spinner multi-spinner"></div>
							</div>
						)}
					</>
				</div>
			)}
		</>
	);
}

import { Figura, FiguraHidden, FiguraSubmitBtn, FiguraLabel, FiguraTextArea, FiguraSelect } from "react-figura";
import { addFeedback } from "../../../Api/feedback";
import { auth } from "../../Auth/Firebase";
import { useState } from "react";
import "./GiveFeedback.css";

export default function GiveFeedback() {
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState({ bool: false, message: "" });

	async function provideFeedback({ type, feedback }) {
		setShowError({ bool: false, message: "" });
		setShowSuccess(false);
		if (feedback) {
			try {
				const response = await addFeedback(auth.currentUser.email, feedback, type);
				if (response) {
					setShowSuccess(true);
				}
			} catch (error) {
				setShowError({ bool: true, message: "there was an issue providing feedback" });
				console.error("Error providing feedback:", error);
			}
		}
	}

	return (
		<div className="update-email">
			<h1>provide feedback</h1>
			<Figura formID="understand" formStyle="figura-form figura-modal" onSubmit={provideFeedback}>
				<FiguraSelect name="type" inputStyle="figura-select" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">type</FiguraLabel>
					<option value="">select type</option>
					<option value="general">general feedback</option>
					<option value="feature">feature request</option>
					<option value="bug">bug</option>
				</FiguraSelect>
				<FiguraTextArea name="feedback" inputStyle="figura-textarea" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">feedback</FiguraLabel>
				</FiguraTextArea>
				<FiguraHidden />
				<FiguraSubmitBtn buttonStyle="figura-button figura-modal">submit</FiguraSubmitBtn>
			</Figura>
			{showSuccess && <p className="success">feedback successfully submitted!</p>}
			{showError.bool && <p className="warning-red">{showError.message}</p>}
		</div>
	);
}

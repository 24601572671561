import { Figura, FiguraEmail, FiguraHidden, FiguraLabel, FiguraSubmitBtn } from "react-figura";
import { updateReplaceEmail } from "../../../Api/user";
import { updateEmail } from "firebase/auth";
import { auth } from "../../Auth/Firebase";
import { useState } from "react";
import "./UpdateEmail.css";

export default function UpdateEmail() {
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState({ bool: false, message: "" });

	async function updateEmailAddress({ oldEmail, newEmail }) {
		setShowError({ bool: false, message: "" });
		setShowSuccess(false);
		if (oldEmail && newEmail) {
			if (oldEmail === auth.currentUser.email) {
				try {
					await updateEmail(auth.currentUser, newEmail).then(() => {
						updateReplaceEmail(oldEmail, newEmail).then(() => {
							setShowSuccess(true);
						});
					});
				} catch (error) {
					setShowError({ bool: true, message: "there was an issue updating your email" });
					console.error("Error updating email:", error);
				}
			} else {
				setShowError({ bool: true, message: "email not found" });
			}
		}
	}

	return (
		<div className="update-email">
			<h1>update email</h1>
			<br />
			<Figura formID="understand" formStyle="figura-form figura-modal" onSubmit={updateEmailAddress}>
				<FiguraEmail name="oldEmail" inputStyle="figura-input" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">old email:</FiguraLabel>
				</FiguraEmail>
				<FiguraEmail name="newEmail" inputStyle="figura-input" errorStyle="figura-error">
					<FiguraLabel labelStyle="figura-label">new email:</FiguraLabel>
				</FiguraEmail>
				<FiguraHidden />
				<FiguraSubmitBtn buttonStyle="figura-button figura-modal">submit</FiguraSubmitBtn>
			</Figura>
			{showSuccess && (
				<p className="success">email successfully updated, we sent a verification email to your new address.</p>
			)}
			{showError.bool && <p className="warning-red">{showError.message}</p>}
		</div>
	);
}

import { TbUserCircle, TbLogin } from "react-icons/tb";
import Tooltip from "../../Layouts/ToolTip/ToolTip";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Menu from "../Menu/Menu";
import "./Header.css";

export default function Header({ showHeaderIcons, user }) {
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = () => setShowMenu(!showMenu);
	const goProfile = () => navigate("/dashboard");
	const goLogin = () => navigate("/login");
	const goHome = () => navigate("/");

	return (
		<>
			<div className={`${showHeaderIcons ? "" : "no-icons"} header`}>
				<Tooltip text={user ? "your dashboard" : "log in"} position="right">
					{showHeaderIcons &&
						(user ? (
							<TbUserCircle size="40px" className="profile-icon" onClick={goProfile} />
						) : (
							<TbLogin size="36px" className="profile-icon" onClick={goLogin} />
						))}
				</Tooltip>
				<h1 onClick={goHome}>pyxis</h1>
				<Tooltip text="menu" position="left">
					{showHeaderIcons && (
						<div onClick={toggleMenu} className="menu-icon">
							<span className={`${showMenu ? "first-line-transition" : ""} menu-first-line`}></span>
							<span className={`${showMenu ? "second-line-transition" : "second-line-spacer"} menu-second-line`}></span>
						</div>
					)}
				</Tooltip>
			</div>
			{showMenu && <Menu setShowMenu={setShowMenu} />}
		</>
	);
}

import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../Auth/Firebase";
import "./Menu.css";

export default function Menu({ setShowMenu }) {
	const location = useLocation();
	const path = location.pathname;
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
	const goHome = () => {
		navigate("/");
		path === "/" && setShowMenu(false);
	};
	const goTerms = () => {
		navigate("/terms");
		path === "/terms" && setShowMenu(false);
	};
	const goPolicy = () => {
		navigate("/policy");
		path === "/policy" && setShowMenu(false);
	};
	const goPrivacy = () => {
		navigate("/privacy");
		path === "/privacy" && setShowMenu(false);
	};
	const goSecurity = () => {
		navigate("/security");
		path === "/security" && setShowMenu(false);
	};
	const goStatistics = () => {
		navigate("/statistics");
		path === "/statistics" && setShowMenu(false);
	};

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= 600);
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	async function logout() {
		try {
			await signOut(auth);
			navigate("/login");
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	}

	return (
		<div className="menu-container">
			{isMobile && <div className="mobile-block"></div>}
			<div className="first-block"></div>
			<div className="second-block"></div>
			<div className="third-block">
				{!isMobile ? (
					<button className="menu-close" onClick={logout}>
						logout
					</button>
				) : (
					<button className="menu-close" onClick={goTerms}>
						terms
					</button>
				)}
			</div>
			<div className="fourth-block">
				<button className="menu-close" onClick={goHome}>
					home
				</button>
			</div>
			<div className="fifth-block">
				<button className="menu-close" onClick={goSecurity}>
					security
				</button>
			</div>
			<div className="sixth-block">
				<button className="menu-close" onClick={goPrivacy}>
					privacy
				</button>
			</div>
			<div className="seventh-block">
				{!isMobile ? (
					<button className="menu-close" onClick={goTerms}>
						terms
					</button>
				) : (
					<button className="menu-close" onClick={goPolicy}>
						policy
					</button>
				)}
			</div>
			<div className="eighth-block">
				{!isMobile ? (
					<button className="menu-close" onClick={goPolicy}>
						policy
					</button>
				) : (
					<button className="menu-close" onClick={goStatistics}>
						statistics
					</button>
				)}
			</div>
			<div className="ninth-block">
				{!isMobile ? (
					<button className="menu-close" onClick={goStatistics}>
						statistics
					</button>
				) : (
					<div className="rules-list">
						<p>
							1. buying:{" "}
							<span>
								you can buy unowned squares for $1. for owned squares you can buy them from the marketplace, or make an offer.
							</span>
						</p>
						<p>
							2. color: <span>if you own a square, you can change its color once a day.</span>
						</p>
						<p>
							3. selling: <span>you can choose to sell a square on the marketplace, or accept an offer if you recieve one.</span>
						</p>
						<p>
							4. transactions:{" "}
							<span>
								all buying and selling happens in the app, deals outside the app will result in immediate ban and removal of all owned
								squares.
							</span>
						</p>
						<p>
							5. respect:{" "}
							<span>
								squares may not be used to create portrayals that are derogatory, racist, anti lgbtq, sexual in nature, or otherwise
								offensive. such actions will be met with immediate ban and removal of all owned squares.
							</span>
						</p>
						<p>
							6. anything else: <span>see our policy</span>
						</p>
					</div>
				)}
			</div>
			<div className="tenth-block">
				<div className="rules-list">
					<p>
						1. buying:{" "}
						<span>you can buy unowned squares for $1. for any owned squares you can make offers for them in your marketplace.</span>
					</p>
					<p>
						2. color: <span>if you own a square, you can change its color once every 30 seconds.</span>
					</p>
					<p>
						3. selling:{" "}
						<span>
							{" "}
							if you own a square, you may receive offers to buy the square from you. If you see an offer you like simply accept the
							offer, the buyer has 48 hours to complete the purchase before the offer is rescinded. Once the purchase is complete the
							funds will be transferred to your paypal.
						</span>
					</p>
					<p>
						4. transactions:{" "}
						<span>
							all buying and selling happens in the app, deals outside the app may result in a ban and removal of all your owned
							squares.
						</span>
					</p>
					<p>
						5. respect:{" "}
						<span>
							squares may not be used to create pixel art portrayals that are derogatory, racist, anti-lgbtq, sexual in nature, or
							otherwise offensive. such actions may result in admin intervention, possible bans from utilizing the marketplace, and
							revocation of all of your owned squares.
						</span>
					</p>
					<p>
						6. anything else: <span>see our policy</span>
					</p>
				</div>
			</div>
		</div>
	);
}

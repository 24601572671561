import {
	Figura,
	FiguraConfirmPassword,
	FiguraEmail,
	FiguraHidden,
	FiguraLabel,
	FiguraPassword,
	FiguraSubmitBtn,
	FiguraText,
	FiguraTitle,
} from "react-figura";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";
import { postUser, userExists } from "../../Api/user";

export default function Register() {
	const navigate = useNavigate();
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const [isLoading, setIsLoading] = useState(false);
	const goLogin = () => {
		navigate("/login");
	};

	async function handleRegister({ username, email, password }) {
		setShowFailure({ bool: false, message: "" });
		setShowSuccess(false);
		setIsLoading(true);
		try {
			const actionCodeSettings = {
				url: "https://pyxisgrid.com/verify",
				handleCodeInApp: true,
			};
			const doesUserExist = await userExists(username, email);
			if (!doesUserExist.username && !doesUserExist.email) {
				const userCredential = await createUserWithEmailAndPassword(auth, email, password);
				await sendEmailVerification(userCredential.user, actionCodeSettings)
					.then(async () => {
						try {
							await postUser(username, email);
							setShowSuccess(true);
						} catch (error) {
							await userCredential.user.delete();
							setShowFailure({ bool: true, message: "Issue registering user" });
						}
					})
					.catch((error) => {
						setShowFailure({ bool: true, message: "Issue sending verification email" });
					});
			} else if (doesUserExist.email) {
				setShowFailure({ bool: true, message: "Email already exists" });
			} else if (doesUserExist.username) {
				setShowFailure({ bool: true, message: "Username already exists" });
			}
		} catch (error) {
			if (error.code === "auth/email-already-in-use") {
				setShowFailure({ bool: true, message: "Email already in use." });
			} else if (error.code === "auth/invalid-email") {
				setShowFailure({ bool: true, message: "Invalid email." });
			} else {
				setShowFailure({
					bool: true,
					message: "There has been an issue creating your account, try again or reach out to us for help.",
				});
			}
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<div className="auth-container">
				<Figura formId="register" formStyle="figura-form" onSubmit={handleRegister}>
					<FiguraTitle titleStyle="figura-title">create an account</FiguraTitle>

					<FiguraText name="username" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">username:</FiguraLabel>
					</FiguraText>

					<FiguraEmail name="email" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">email:</FiguraLabel>
					</FiguraEmail>

					<FiguraPassword name="password" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">password:</FiguraLabel>
					</FiguraPassword>

					<FiguraConfirmPassword name="confirm" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
						<FiguraLabel labelStyle="figura-label">confirm password:</FiguraLabel>
					</FiguraConfirmPassword>

					<FiguraHidden />

					<FiguraSubmitBtn buttonStyle="figura-button">submit</FiguraSubmitBtn>
				</Figura>
				{isLoading && <div className="spinner small-spinner"></div>}
				{showFailure.bool && <p className="not-verified ver-left">{showFailure.message}</p>}
				{showSuccess && (
					<p className="verified ver-left">
						success! we've sent you a verification email. if you don't see the email in your inbox be sure to check your
						junk/spam box. Look for an email from noreply@pyxis-d1790.firebaseapp.com.
					</p>
				)}

				<button onClick={goLogin} className="figura-button">
					back to login
				</button>
			</div>
		</>
	);
}

import { AiOutlineCloseCircle } from "react-icons/ai";
import { changeColor } from "../../../../Api/square";
import { useState } from "react";
import "./ColorPicker.css";

export default function ColorPicker({ squareData, setIsColorPickerOpen, setSquareData }) {
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({ bool: false, message: "" });
	if (!squareData) setIsColorPickerOpen(false);

	function newColor(e) {
		e.preventDefault();
		setIsLoading(true);
		changeColor(e.target[0].value, squareData.id).then((res) => {
			if (res.status === 200) {
				setIsSuccess(true);
				setIsLoading(false);
				setTimeout(() => {
					setIsColorPickerOpen(false);
					setSquareData(null);
				}, 4000);
			} else if (res.status === 429) {
				setIsLoading(false);
				setError({
					bool: true,
					message: "You can only change the color of a square once every 30 seconds. Please wait and try again.",
				});
			} else {
				setIsLoading(false);
				setError({ bool: true, message: "An error occurred, please try again." });
			}
		});
	}

	function closeColorPicker() {
		setIsColorPickerOpen(false);
	}

	return (
		<>
			{squareData && (
				<div className="color-picker">
					{!isSuccess ? (
						<>
							<AiOutlineCloseCircle size="16px" className="close-icon" onClick={closeColorPicker} />
							<h1>choose a new color</h1>
							<p>current color: {squareData.color}</p>
							<form onSubmit={newColor} className="color-picker-form">
								<div className="color-picker-input">
									<label>new color:</label>
									<div className="color-picker-container">
										<input className="color-picker-style" type="color" />
									</div>
								</div>
								<button>confirm</button>
								{isLoading && <div className="spinner" />}
							</form>
							{error.bool && <p className="square-error">{error.message}</p>}
						</>
					) : (
						<>
							<h1>Success!!</h1>
							<p>please wait up to 30 seconds to see your changes reflected on the canvas</p>
						</>
					)}
				</div>
			)}
		</>
	);
}

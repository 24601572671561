import SecurityPolicy from "../../Helpers/SecurityPolicy.json";
import ReactQuill from "react-quill";

export default function Security() {
	return (
		<div className="legals">
			<ReactQuill value={SecurityPolicy} readOnly={true} modules={{ toolbar: false }} />
		</div>
	);
}
